.App {
  text-align: center;
  /* height: 100vh; */
  /* overflow: hidden; */
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.ellipsis-2 {
  max-width: 100vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 0px;
  padding-top: 0px;
  line-height: 1.5;
}

.box-2 {
  display: flex;
  direction: rtl;

  background-color: black;
  position: absolute;
  bottom: 50px;
  left: 100px;
  right: 100px;
  width: calc(100vw-200) px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
}

.ellipsis {
  -webkit-box-orient: vertical;
  background-color: black;
  display: -webkit-box;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box {
  bottom: 50px;
  direction: rtl;
  display: flex;
  font-size: 30px;
  justify-content: center;
  left: 100px;
  margin-left: auto;
  margin-right: auto;
  max-height: 135px;
  overflow-y: scroll;
  overflow: hidden;
  position: absolute;
  right: 100px;
  width: calc(100vw-200) px;
  z-index: 10;
}

.canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
}

.video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.wrapper {
  display: flex;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
